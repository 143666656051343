import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/shop/login'),
  },
  // {
  //   path: '/admin/login',
  //   name: 'adminLogin',
  //   component: () => import('@/views/admin/login'),
  // },
  // {
  //   path: '/wechat',
  //   name: 'wechat',
  //   component: () => import('@/views/wechat/result'),
  // },
  // {
  //   path: '/admin',
  //   name: 'admin',
  //   redirect: '/admin/shopList',
  //   component: () => import('@/views/admin/index'),
  //   children: [
  //     {
  //       path: '/admin/shopList',
  //       component: () => import('@/views/admin/shopList'),
  //     },
  //     {
  //       path: '/admin/productList',
  //       component: () => import('@/views/admin/productList'),
  //     },
  //     {
  //       path: '/admin/analyseList',
  //       component: () => import('@/views/admin/analyseList'),
  //     },
  //   ],
  // },
  {
    path: '/shop',
    name: 'shop',
    redirect: '/shop/my',
    component: () => import('@/views/shop/index'),
    children: [
      {
        path: '/shop/my',
        component: () => import('@/views/shop/my'),
      },
      {
        path: '/shop/productList',
        component: () => import('@/views/shop/productList'),
      },
      {
        path: '/shop/analyseList',
        component: () => import('@/views/shop/analyseList'),
      },
    ],
  },
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push
let routerReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err)
}
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch((err) => err)
}

const router = new Router({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes,
})

export default router
