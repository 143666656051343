import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: null,
    tenantId: null,
    userName: null,
    userInfo: null
  },
  mutations: {
    // token
    SET_TOKEN: (state, token) => {
      state.token = token
      window.localStorage.setItem('token', token)
    },
    REMOVE_TOKEN: (state) => {
      state.token = null
      window.localStorage.removeItem('token')
    },

    // tenantId
    SET_TENANTID: (state, id) => {
      state.tenantId = id;
      window.localStorage.setItem("tenantId", id);
    },
    REMOVE_TENANTID: state => {
      state.tenantId = null;
      window.localStorage.removeItem("tenantId");
    },

    // userName
    SET_USERNAME: (state, userName) => {
      state.userName = userName;
      window.localStorage.setItem("userName", userName);
    },
    REMOVE_USERNAME: state => {
      state.userName = null;
      window.localStorage.removeItem("userName");
    },

    // userInfo
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo;
      window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    REMOVE_USERINFO: state => {
      state.userInfo = null;
      window.localStorage.removeItem("userInfo");
    },
  },
  getters: {},
  actions: {
    // 退出登录
    outLoginClick({ commit }) {
      commit('REMOVE_TOKEN')
      commit('REMOVE_TENANTID')
      commit('REMOVE_USERNAME')
      commit('REMOVE_USERINFO')
    },
    // 如有缓存直接赋值vuex 不去请求接口
    loadLocal({ commit }) {
      const token = window.localStorage.getItem('token')
      if (token && token != 'undefined') {
        commit('SET_TOKEN', token)
      }
      const tenantId = window.localStorage.getItem('tenantId')
      if (tenantId && tenantId != 'undefined') {
        commit('SET_TENANTID', tenantId)
      }
      const userName = window.localStorage.getItem('userName')
      if (userName && userName != 'undefined') {
        commit('SET_USERNAME', userName)
      }
      const userInfo = window.localStorage.getItem('userInfo')
      if (userInfo && userInfo != 'undefined') {
        commit('SET_USERINFO', JSON.parse(userInfo))
      }
    },
  },
})

export default store
